// Description: Contains a class that converts between metric and imperial units
// Usage:
// import { UnitConverter } from '@/assets/js/utils/unit-converter'
//
// const unitConverter = new UnitConverter()
// unitConverter.convert(1, 'metric') // '1 m'
// unitConverter.convert(1, 'imperial') // '3.28 ft'
// unitConverter.convertToMetersFromMetric(1, 'cm') // 0.01

export const METERS_TO_CENTIMETERS = 100
export const METERS_TO_FEET = 3.28084
export const FEET_TO_INCHES = 12
export const INCHES_TO_METERS = 0.0254
export const YARDS_TO_METERS = 0.9144

export class UnitConverter {
    
  // Returns a string representation of the value in the unit system specified
    static convert(value, unitSystem) {
    if (unitSystem === 'imperial') {
      return this.getImperialLabel(value)
    } else {
      return this.getMetricLabel(value)
    }
  }

  // Returns a string representation of the value in metric units
  static getMetricLabel(meters) {
    if (meters < 1 && meters > -1) {
      return `${(meters * METERS_TO_CENTIMETERS).toFixed(0)} cm`
    } else {
      return `${meters.toFixed(2)} m`
    }
  }

  // Converts meters to feet and inches
  static convertImperial(meters) {
    const totalFeet = meters * METERS_TO_FEET
    const feet = Math.trunc(totalFeet) // Get the integer part
    const inches = Math.abs(totalFeet - feet) * FEET_TO_INCHES
    return {
      feet: feet,
      inches: inches
    }
}

  // Returns a string representation of the value in imperial units
  static getImperialLabel(meters) {
    // convert to feet and inches
    const imperial = this.convertImperial(meters)

    if (imperial.feet < 1 && imperial.feet > -1) {
      return `${(imperial.inches).toFixed(1)} in`
    }
    else{
        return `${imperial.feet} ft ${imperial.inches.toFixed(1)} in`
      }
  }

  // Converts a metric value to either centimeters or meters
  static convertToMetersFromMetric(value, unit) {
    if (unit === 'cm') {
      return value / METERS_TO_CENTIMETERS
    } else {
      // Assume the unit is meters if it's not centimeters
      return value
    }
  }

  // Converts an imperial value to meters
  static convertToMetersFromImperial(value, unit) {
    if (unit === 'in') {
      return value / (METERS_TO_FEET * FEET_TO_INCHES)
    } else {
      // Assume the unit is feet if it's not inches
      const [feet, inches] = value.split(' ').map(Number)
      return (feet + inches / FEET_TO_INCHES) / METERS_TO_FEET
    }
  }

  // Converts radians to degrees
  static radToDeg(radians) {
    return radians * (180 / Math.PI)
  }
}
