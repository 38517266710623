<!-- =========================================================================================
    VUnitInput
    A component that allows the user to input a value in either metric or imperial units.
    ========================================================================================= -->

<template>
    <div>
    <vs-input
        :value="displayValue"
        :label="displayLabel"
        :v-validate="vValidate"
        :name="name"
        :size="size"
        :class="cssClass"
        v-on:change="onInput"
         />
    </div>
</template>

<script>
import { UnitConverter } from '@/assets/js/utils/unit-converter.js'

export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    unitSystem: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    vValidate: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
  },
  computed: {
    displayValue() {
      if (this.unitSystem === 'imperial') {
          // Convert meters to feet

        return (this.value * 3.28084).toFixed(2);
      }
      return this.value;
    },
    displayLabel() {
      if (this.unitSystem === 'imperial') {
          // Convert meters to feet

        return (this.label.concat(' (ft)'));
      }
      return this.label;
    },
  },
  methods: {
      onInput(event) {
          this.$nextTick(() => {
              let inputValue = parseFloat(event.target.value);
              if (this.unitSystem === 'imperial') {
                  // Convert feet to meters
                  inputValue = inputValue / 3.28084;
              }
              this.$emit('input', inputValue);
          });
    },
  },
};
</script>