// Description: Command pattern for undo/redo actions
// Usage:
// import { CommandStack } from '@/assets/js/utils/command-stack'
// import { TranslateNodeCommand } from '@/assets/js/utils/commands'
// 
// const commandStack = new CommandStack()
// commandStack.do(new TranslateNodeCommand(node, { x: 1, y: 2, z: 3 }))
// commandStack.undo()
// commandStack.redo()
//

// Root class for all commands
class Command {
  constructor(commandType) {
    this.type = commandType
    this.was_saved = false
  }
  do() {
    throw new Error('do() not implemented')
  }
  undo() {
    throw new Error('undo() not implemented')
  }
}

// Parent class for all node commands
class NodeCommand extends Command{
  constructor(node) {
    super('node')
    this.node = node
  }
  do() {
    if (this.node.is_dirty == undefined || !this.node.is_dirty) {
      this.was_saved = true // If the node was not dirty before the command, we'll flag it as the saved state
      this.node.is_dirty = true
    }
    else {
      this.node.is_dirty = !this.was_saved // When doing, we'll set the dirty flag to false if we reached the saved state
    }
  }
  undo() {
    if (this.node.is_dirty == undefined || !this.node.is_dirty) {
      this.was_saved = true // If the node was not dirty before the command, we'll flag it as the saved state
      this.node.is_dirty = true
    } else {
      this.node.is_dirty = !this.was_saved // When undoing, we'll set the dirty flag to false if we reached the saved state
    }
  }
}

class TransformNodeToCommand extends NodeCommand {
  constructor(node, newNode) {
    super(node)
    this.newNode = newNode
  }

  do() {
    super.do()
    //copy current node into oldNode
    this.oldNode = {
      x: this.node.x,
      y: this.node.y,
      z: this.node.z,
      quaternion_x: this.node.quaternion_x,
      quaternion_y: this.node.quaternion_y,
      quaternion_z: this.node.quaternion_z,
      quaternion_w: this.node.quaternion_w,
      angle_x: this.node.angle_x,
      angle_y: this.node.angle_y,
      angle_z: this.node.angle_z,
      scale: this.node.scale
    }

    // copy newNode into node
    this.node.x = this.newNode.x
    this.node.y = this.newNode.y
    this.node.z = this.newNode.z
    this.node.quaternion_x = this.newNode.quaternion_x
    this.node.quaternion_y = this.newNode.quaternion_y
    this.node.quaternion_z = this.newNode.quaternion_z
    this.node.quaternion_w = this.newNode.quaternion_w
    this.node.angle_x = this.newNode.angle_x
    this.node.angle_y = this.newNode.angle_y
    this.node.angle_z = this.newNode.angle_z
    this.node.scale = this.newNode.scale

  }

  undo() {
    super.undo()
    this.node.x = this.oldNode.x
    this.node.y = this.oldNode.y
    this.node.z = this.oldNode.z
    this.node.quaternion_x = this.oldNode.quaternion_x
    this.node.quaternion_y = this.oldNode.quaternion_y
    this.node.quaternion_z = this.oldNode.quaternion_z
    this.node.quaternion_w = this.oldNode.quaternion_w
    this.node.angle_x = this.oldNode.angle_x
    this.node.angle_y = this.oldNode.angle_y
    this.node.angle_z = this.oldNode.angle_z
    this.node.scale = this.oldNode.scale
  }
}

class NodeGroupCommand extends Command {
  constructor() {
    super('node_group')
    this.commands = []
  }

  add(command) {  
    this.commands.push(command)
  }

  do() {
    for (const command of this.commands) {
      command.do()
    }
  }

  undo() {
    for (const command of this.commands.reverse()) {
      command.undo()
    }
  }
}


export { TransformNodeToCommand, NodeGroupCommand }
