function removeNodeInElement(el, nodePid) {
  // targets
  if (el.targets && el.targets.length > 0 && el.targets.includes(nodePid)) {
    var index = el.targets.indexOf(nodePid)
    if (index !== -1) {
      el.targets.splice(index, 1)
    }
    if (el.targets.length == 0) return null
  }
  // target
  if (el.target && el.target == nodePid) return null
  return el
}
function isTrigger(type) {
  return type.includes('trigger')
}
// removes nodePid from el and all its sub-elements if el is a time-container (sequential or parallel)
// return null if el is no longer valid
function removeNodeRecursively(el, nodePid) {
  if (el.type == 'sequential' || el.type == 'parallel') {
    var i = el.elements.length
    while (i--) {
      var type = el.elements[i].type
      // remove nodePid from the current element recursively
      // if null remove the element itself
      if (removeNodeRecursively(el.elements[i], nodePid) == null) {
        // we need to remove the trigger
        el.elements.splice(i, 1)
        // if we just removed the initial trigger of the sequences then the sequence is invalid
        if (isTrigger(type) && i == 0) {
          return null
        }
      }
    }
    // if the sequence has only one trigger then the sequence is invalid
    if (el.elements.length == 1 && isTrigger(el.elements[0].type)) {
      return null
    }
  } else {
    if (removeNodeInElement(el, nodePid) == null) {
      return null
    }
  }
  return el
}

export { removeNodeRecursively }
