import * as THREE from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'

// Fires when the user starts interacting with the controls
const _startedEvent = { type: 'interaction-started' }
// Fires when the value of the transforms has changed
const _updatedEvent = { type: 'interaction-updated' }
// Fires when the value of the transforms has changed
const _endedEvent = { type: 'interaction-ended' }
// Fires when users begin interacting with the object selector
const _onDraggingChangedEvent = { type: 'dragging-changed' }

// Description: This class is used to select one or more objects in the scene and enable the user to transform them
// Usage:
// 1. Create an instance of the ObjectSelector class
// 2. Add or remove objects to the selection using the add() or remove() methods
// 3. Call refresh() to update the selection box helper
// 4. Call setTranslationSnap(), setRotationSnap() or setScaleSnap() to set the snap values
// 5. Call changeTransformControlMode() to change the transform control mode (translate, rotate, scale)
class ObjectSelector extends EventTarget {
  constructor(scene, camera, domElement, unitSystem, showSelectionBox = false, leftHandCoordinateSystem = false) {
    super()

    this.selection = []
    this.scene = scene
    this.camera = camera
    this.originTransform = null
    this.domElement = domElement
    this.unitSystem = unitSystem
    this.showSelectionBox = showSelectionBox
    this.leftHandCoordinateSystem = leftHandCoordinateSystem

    this.group = new THREE.Group() // Create a group to hold the selected objects

    this.scene.add(this.group)

    this.selectionBox = new THREE.Box3() // Create a box to calculate the bounding box of the group

    this.boxHelper = this.createBoxHelper()

    // this.scene.add(this.boxHelper)

    this.transformControls = this.addTransformControls(camera, domElement)

    this.changeTransformControlMode(this.controlModeFloor)

    this.scene.add(this.transformControls)
  }

  dispose() {
    this.clear()
    this.unregisterTransformControlsEvents()

    if (this.transformControls) {
      this.scene.remove(this.transformControls)
      this.transformControls.dispose()
      this.transformControls = null
    }
    this.selectionBox = null
    if (this.boxHelper) {
      this.scene.remove(this.boxHelper)
      this.boxHelper.dispose()
      this.boxHelper = null
    }
    if (this.boxMat) {
      this.boxMat.dispose()
      this.boxMat = null
    }
    if (this.group) {
      this.scene.remove(this.group)
      this.group = null
    }
    this.scene = null
  }

  selectedObjects() {
    return this.selection
  }

  add(object) {

    this.selection.push(object)

    this.show()

  }

  remove(object) {
    this.selection = this.selection.filter(o => o.name != object.name)

    if (this.isEmpty()) {
      this.hide()
    } else {
      this.show()
    }
  }

  setOriginTransform(originTransform) {
    this.originTransform = originTransform
  }

  size() {
    return this.selection.length
  }

  isSelected(pid) {
    return this.selection.find(o => o.name == pid)
  }

  isEmpty() {
    return this.selection.length === 0
  }

  hasMultipleObjects() {
    return this.selection.length > 1
  }

  clear() {
    // Remove all objects from the selection one by one
    while (this.selection.length > 0) {
      this.remove(this.selection[0])
    }
  }

  groupSelectedObjects() {
    // Add all selection to the group and keep a reference to the previous parent to re-attach them later
    this.selection.forEach((object, i) => {
      // Save the parent of object if it has a parent
      if (object.parent !== null) {
        object.userData.previousParent = object.parent
      }
      else {
        object.userData.previousParent = this.scene
      }
      this.group.attach(object)
    })
  }
  
  ungroupSelectedObjects() {
    // Remove all group children from group and re-attach to previous parent
    this.selection.forEach((object, i) => {
      // Re-attach the object to its previous parent
      object.userData.previousParent.attach(object)
      object.userData.previousParent = null
    })
  }

  show() {
    // If multiple objects are selected, reset the group rotation and scale to restart at 0
    if (this.hasMultipleObjects()) {
      // Set the group rotation and scale to match the parent anchor
      this.group.quaternion.copy(this.originTransform.quaternion)
      this.group.scale.copy(this.originTransform.scale)
      // this.group.quaternion.set(0, 0, 0, 1) // Reset group rotation so as to always restart rotation at 0. The w component is 1 for identity quaternion
      // this.group.scale.set(1, 1, 1) // Reset scale, as to restart group scaling at 1
    } else {
      // single object. Make the group transform match the object transform so that displayed valued match the object transform
      // Since the group is attached to the scene directly (not the parent anchor), we need to set the group rotation and scale to match the object world rotation and scale
      this.selection[0].getWorldQuaternion(this.group.quaternion)
      this.selection[0].getWorldScale(this.group.scale)
    }
    this.updateGizmos()
    
    if (this.selection.length >= 0) {
      if (this.showSelectionBox) {
        this.boxHelper.material.opacity = 1
      }
      this.scene.add(this.boxHelper)
      this.transformControls.attach(this.group)
      this.scene.add(this.transformControls)
    }
  }

  hide() {
    this.boxHelper.material.opacity = 0
    this.scene.remove(this.boxHelper)

    this.transformControls.detach()
    this.scene.remove(this.transformControls)
  }

  updateSelectionBox() {
    this.selectionBox.makeEmpty()

    // For each object in the selection, expand the box to include the object
    this.selection.forEach(object => {

      // Retrieve the bounding box of the object. For that, we use the precomputed boxHepler placed under the modelGroup child object, and named boxHelper
      const boxHelper = object.getObjectByName('modelGroup').getObjectByName('boxHelper')

      if (!boxHelper) {
        console.error('[Hoverlay] Box helper not found. Make sure all objects have a modelGroup child object with a boxHelper object.')
        return 
      }

      const bbox = HoverlayThreeUtils.computeBoundingBox(boxHelper)

      this.selectionBox.union(bbox)
    })
  }

  updateGizmos()
  {
    this.updateSelectionBox()

    // Return if the selection is empty
    if (this.selection.length === 0) {
      return
    }

    if (this.hasMultipleObjects()) { // If we have multiple objects, set the group position to the box center

      const pivotPoint = new THREE.Vector3()
      this.selectionBox.getCenter(pivotPoint)
      pivotPoint.y = this.selectionBox.min.y
      this.group.position.set(pivotPoint.x, pivotPoint.y, pivotPoint.z)

    } else {  // If we have a single object, set the group transform to the object transform
      this.selection[0].getWorldPosition(this.group.position)
    }
  }

  // Reset the selection box helper and the distance helper to match the group
  refresh() {
    this.updateSelectionBox()
  }

  setTranslationSnap(translationSnap) {
    this.transformControls.setTranslationSnap(translationSnap)
  }
  setRotationSnap(rotationSnap) {
    this.transformControls.setRotationSnap(rotationSnap)
  }
  setScaleSnap(scaleSnap) {
    this.transformControls.setScaleSnap(scaleSnap)
  }

  // UTILITY FUNCTIONS
  // -----------------
  alignToFloor() {
    // 1. Calculate the distance from the bottom center of the box to the floor
    const transformOrigin = new THREE.Vector3()
    this.selectionBox.getCenter(transformOrigin)
    transformOrigin.y = this.selectionBox.min.y
    // 2. Set the group position to the floor
    this.group.position.y -= transformOrigin.y
  }

  alignBottom() {
    // 1. Calculate the lowest vertical point of the selected objects
    const transformOrigin = new THREE.Vector3()
    this.selectionBox.getCenter(transformOrigin)
    // Lower each selected object so that their lowest point is at the same level as the lowest point of the group
    this.selection.forEach(object => {
      // compute the lower point of the object
      const objectBox = new THREE.Box3().setFromObject(object)
      object.position.y += this.selectionBox.min.y - objectBox.min.y
    })
  }

  alignTop() {
    // 1. Calculate the highest vertical point of the selected objects
    const transformOrigin = new THREE.Vector3()
    this.selectionBox.getCenter(transformOrigin)
    // Lower each selected object so that their lowest point is at the same level as the lowest point of the group
    this.selection.forEach(object => {
      // compute the lower point of the object
      const objectBox = new THREE.Box3().setFromObject(object)
      object.position.y += this.selectionBox.max.y - objectBox.max.y
    })
    this.dispatchEvent(
      new CustomEvent(_endedEvent.type, {
        detail: { selection: this.selection, mode: this.transformControls.mode },
      })
    )
  }

  distributeHorizontally() {
    // 1. Calculate the distance between each object
    const transformOrigin = new THREE.Vector3()
    this.selectionBox.getCenter(transformOrigin)
    const distance = this.selectionBox.getSize().x / (this.selection.length - 1)
    // 2. Sort the selection by x position
    this.selection.sort((a, b) => a.position.x - b.position.x)
    // 3. Distribute the objects horizontally
    this.selection.forEach((object, i) => {
      object.position.x = transformOrigin.x - this.selectionBox.getSize().x / 2 + distance * i
    })
  }

  changeTransformControlMode(mode) {
    switch (mode) {
      case 'scale':
        this.transformControls.showZ = false
        this.transformControls.showX = false
        this.transformControls.showY = true
        this.transformControls.setMode('scale')
        this.transformControls.setSpace('local')
        break

      case 'rotate':
        // Rotate
        this.transformControls.showX = true
        this.transformControls.showY = true
        this.transformControls.showZ = true
        this.transformControls.setMode('rotate')
        this.transformControls.setSpace('local')
        break

      case 'translate':
      default:
        this.transformControls.showZ = true
        this.transformControls.showX = true
        this.transformControls.showY = true
        this.transformControls.setMode('translate')
        this.transformControls.setSpace('world')
        break
    }
  }

  unregisterTransformControlsEvents() {
    this.transformControls && this.transformControls.removeEventListener('change', this.render)
    this.transformControls && this.transformControls.removeEventListener('mouseDown', this.mouseDownHandler)
    this.transformControls && this.transformControls.removeEventListener('mouseUp', this.mouseUpHandler)
    this.transformControls && this.transformControls.removeEventListener('objectChange', this.objectChanged)
    this.transformControls && this.transformControls.removeEventListener('dragging-changed', this.onDraggingChanged)
  }

  createBoxHelper() {
    const boxHelper = new THREE.Box3Helper(this.selectionBox) // Create a box helper to visualize the bounding box of the group
    const boxMat = new THREE.LineBasicMaterial()
    //boxMat.color.setHex(0xffd22e) // Set to primary color 0x7c53e6
    boxMat.color.setHex(0xb545454) // Set to primary color to blue
    //boxMat.color.setHex(0xffd22e) // Set to primary color to blue
    boxHelper.material = boxMat
    boxHelper.material.transparent = true
    boxHelper.material.opacity = 0
    return boxHelper
  }

  addTransformControls(currentCamera, domElement) {
    const transformControls = new TransformControls(currentCamera, domElement)
    transformControls.space = 'world'
    transformControls.size = 1
    // transformControls.addEventListener('change', this.render)
    // Disable hobject selection when a transformControls is active (avoid conflict event)
    transformControls.addEventListener('dragging-changed', event => this.onDraggingChanged(event))
    transformControls.addEventListener('objectChange', event => this.objectChanged(event))
    transformControls.addEventListener('mouseUp', event => this.mouseUpHandler(event))
    transformControls.addEventListener('mouseDown', event => this.mouseDownHandler(event))
    transformControls.customParamTransform = this.group

    var gizmoRotateX = transformControls._gizmo.gizmo['rotate'].children.find(mesh => mesh.name == 'X')
    gizmoRotateX.material.color.setHex(0xab3741)

    var gizmoRotateY = transformControls._gizmo.gizmo['rotate'].children.find(mesh => mesh.name == 'Y')
    gizmoRotateY.material.color.setHex(0x87d68a)

    // var gizmoTranslateX = transformControls._gizmo.gizmo['translate'].children.find(mesh => mesh.name == 'X')
    // gizmoTranslateX.material.color.setHex(0x87d68a)

    // var gizmoTranslateY = transformControls._gizmo.gizmo['translate'].children.find(mesh => mesh.name == 'Y')
    // gizmoTranslateY.material.color.setHex(0xab3741)

    var gizmoTranslateZ = transformControls._gizmo.gizmo['translate'].children.find(mesh => mesh.name == 'Z')
    gizmoTranslateZ.material.color.setHex(0x4185d0)

    // Snap/Magnetic
    transformControls.setTranslationSnap(0.01)

    //transformControls.attach(this.group)

    return transformControls
  }

  mouseUpHandler(event) {
    this.ungroupSelectedObjects()

     this.dispatchEvent(
       new CustomEvent(_endedEvent.type, { detail: { selection: this.selection, mode: this.transformControls.mode } })
    )
  }
  mouseDownHandler(event) {
    this.groupSelectedObjects()

    this.dispatchEvent(
      new CustomEvent(_startedEvent.type, { detail: { selection: this.selection, mode: this.transformControls.mode } })
    )
  }

  objectChanged(event) {
    this.group.scale.x = this.group.scale.z = this.group.scale.y = Math.abs(this.group.scale.y)

    this.dispatchEvent(
      new CustomEvent(_updatedEvent.type, { detail: this.group }))
  }

  onDraggingChanged(event) {
    // true is dragging, false is not dragging
    const dragging = event.value

    this.dispatchEvent(new CustomEvent(_onDraggingChangedEvent.type, {
      detail: {
        dragging: dragging,
        group: this.group,
        originTransform: this.originTransform,
        mode: this.transformControls.mode,
        axis: this.transformControls.axis
      }
    }))
  }
}

export { ObjectSelector }
