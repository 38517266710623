import * as THREE from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2'
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial'
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry'
import { UnitConverter } from '@/assets/js/utils/unit-converter.js'
import * as HoverlayThreeUtils from '@/assets/js/utils/hoverlay-three-utils.js'

// Description: Helper class for displaying measurement between a point and a transform
// Usage:
// import { DistanceHelper } from '@/assets/js/utils/distance-helper.js'
//
// const distanceHelper = new DistanceHelper(scene, camera, domElement, originPosition, targetTransform, unitSystem)
// distanceHelper.attach(targetTransform)
// distanceHelper.update()
// distanceHelper.detach()
// distanceHelper.dispose()
//
// Attributes:
// scene: the scene to add the distance line to
// camera: the camera to use for projecting the distance text
// domElement: the element containing the canvas
// originPosition: the position of the origin point
// targetTransform: the transform to measure the distance to
// unitSystem: the unit system to use for displaying the distance. One of 'metric' or 'imperial'
const LINE_COLOR = 0xffd22e; // 0xff7777// 0x8b8000
const GROUND_OFFSET = 0.01 // distance from the ground to avoid z-fighting

export class DistanceHelper {
    constructor(scene, camera, domElement, unitSystem, leftHandCoordinateSystem = false) {
        this.scene = scene
        this.camera = camera
        this.domElement = domElement
        this.unitSystem = unitSystem
        this.leftHandCoordinateSystem = leftHandCoordinateSystem
        this.line = null
        this.matLine = null
        this.distance = 0
        this.lineGeometry = null
        this.text = '-'
        this.relativePositionMatrix = null
        this.UnityConvertedTransform = null

        // Create an empty array of 4 points to represent the line
        this.linePoints = []

        // Create Annotation
        this.createAnnotations()
        
        // Create distance markers for segments end
        this.createSegmentEnding()
    }

    attach(targetTransform, originTransform, mode, activeAxes) {

        if (!targetTransform || !originTransform) return // If no target, do nothing

        this.targetTransform = targetTransform
        this.originTransform = originTransform
        this.mode = mode

        // Invert axis if the anchor object is rotated in relation to the world
        // This is not pretty but it works

        if (mode === 'translate') {
            var correctedAxis = activeAxes.slice()

            if (this.originTransform.rotation.x !== 0) {
                correctedAxis = correctedAxis.replace(/[YZ]/g, function (c) {
                    if (c === 'Y')
                        return 'Z'
                    else if (c === 'Z')
                        return 'Y'
                    else
                        return c
                });
            }
            this.activeAxes = correctedAxis
        } else {
            this.activeAxes = activeAxes
        }

        // Create line if not exist
        // switch (this.mode) {
        //     case 'translate':
        //         if (!this.line) this.createDistanceLines()
        //         break;
        //     case 'rotate':
        //     case 'scale':
        //         break;
        // }
        
    }

    detach() {
        this.targetTransform = null

        if (this.matLine)
            this.matLine.dispose()
        if (this.line) {
            this.line.geometry.dispose()
            this.line.material.dispose()
            this.scene.remove(this.line)
            this.line = null
        }
        this.resetDiv(this.selectorPositionX)
        this.resetDiv(this.selectorPositionY)
        this.resetDiv(this.selectorPositionZ)
        this.resetDiv(this.selectorRotationAngle)
        this.resetDiv(this.selectorScale)

        this.resetDiv(this.segmentEnding1)
        this.resetDiv(this.segmentEnding2)
        this.resetDiv(this.segmentEnding3)
        this.resetDiv(this.segmentEnding4)
    }

    update() {
        // If no target, no update.
        if (!this.targetTransform) return

        // We need to display the relative position of the group to the anchor object in Unity coordinates
        // 1. Get the group matrix
        const groupMatrix = this.targetTransform.matrixWorld
        const anchorMatrix = this.originTransform.matrixWorld
        // 2. Compute the inverse matrix of the anchor object
        var inverseMatrix = anchorMatrix.clone().invert()
        // 3. Compute the relative position matrix
        this.relativePositionMatrix = inverseMatrix.multiply(groupMatrix)
        // 4. Convert the relative position matrix to unity coordinates
        this.UnityConvertedTransform = HoverlayThreeUtils.threejsMatrixToUnity(this.relativePositionMatrix)

        // Update lines
        switch (this.mode) {
            case 'translate':

                var right = new THREE.Vector3(1, 0, 0)
                var forward = new THREE.Vector3(0, 0, 1)
                var up = new THREE.Vector3(0, 1, 0)

                // Compute linepoints
                this.linePoints[0] = [this.originTransform.position.x, this.originTransform.position.y, this.originTransform.position.z]

                // Retrive the x delta from the relativePositionMatrix
                var targetRelativePosition = new THREE.Vector3()
                targetRelativePosition.setFromMatrixPosition(this.relativePositionMatrix)

                // return [position.x, GROUND_OFFSET, 0]
                right.applyQuaternion(this.originTransform.quaternion)
                right.multiplyScalar(targetRelativePosition.x)
                this.linePoints[1] = [right.x, right.y, right.z]

                // return [position.x, GROUND_OFFSET, 0]
                forward.applyQuaternion(this.originTransform.quaternion)
                forward.multiplyScalar(targetRelativePosition.z)
                forward.add(right)
                this.linePoints[2] = [forward.x, forward.y, forward.z]

                up.applyQuaternion(this.originTransform.quaternion)
                up.multiplyScalar(targetRelativePosition.y)
                up.add(forward)
                this.linePoints[3] = [up.x, up.y, up.z]

                this.refreshDistanceLines()
                // Update segment endings
                this.refreshSegmentEndings()
                break;
            case 'rotate':
            case 'scale':
                break;
        }
        // Update annotation
        this.refreshAnnotations()
    }

    createDistanceLines() {
        // Update line styling (dash size, gap size, width, etc.) based on distance between the camera and targetTransform.position
        this.distance = this.camera.position.distanceTo(this.targetTransform.position)

        const linewidth = this.getLineWidth();
        const lineDashed = this.getLineDashed();
        const lineDashScale = this.getLineDashScale();
        const lineDashSize = this.getLineDashSize();
        const lineGapSize = this.getLineGapSize();

        this.matLine = new LineMaterial({
            color: LINE_COLOR,
            worldUnits: true,
            linewidth: linewidth, 
            vertexColors: false,
            //resolution:  // to be set by renderer, eventually
            dashed: lineDashed,
            dashScale: lineDashScale,
            dashSize: lineDashSize,
            gapSize: lineGapSize,
            alphaToCoverage: true,
        })
        var lineGeometry = new LineGeometry()
        let positions = [] //new Float32Array(2 * 3); // 3 vertices per point
        positions.push(...this.linePoints[0])
        positions.push(...this.linePoints[1])
        positions.push(...this.linePoints[2])
        positions.push(...this.linePoints[3])
        lineGeometry.setPositions(positions)
        // // lineGeometry.vertices.push(this.originTransform.position, this.targetTransform.position);
        this.line = new Line2(lineGeometry, this.matLine)
        this.line.computeLineDistances()
        this.line.scale.set(1, 1, 1)
        this.scene.add(this.line)
    }

    refreshDistanceLines() {
        if (!this.line)
            this.createDistanceLines()

        let positions = [] //new Float32Array(2 * 3); // 3 vertices per point
        positions.push(...this.linePoints[0])
        positions.push(...this.linePoints[1])
        positions.push(...this.linePoints[2])
        positions.push(...this.linePoints[3])
        this.line.geometry.setPositions(positions)
        this.line.geometry.verticesNeedUpdate = true
        this.line.computeLineDistances()
        this.line.scale.set(1, 1, 1)
    }

    createAnnotations() {
        // create empty div, of class "annotation", each use to display the respective x,y,z value of the selector
        this.selectorPositionX = document.createElement('div')
        this.selectorPositionY = document.createElement('div')
        this.selectorPositionZ = document.createElement('div')
        this.selectorRotationAngle = document.createElement('div')
        this.selectorScale = document.createElement('div')
        // Add class to the div
        this.selectorPositionX.classList.add('distance-annotation')
        this.selectorPositionY.classList.add('distance-annotation')
        this.selectorPositionZ.classList.add('distance-annotation')
        this.selectorRotationAngle.classList.add('rotation-annotation')
        this.selectorScale.classList.add('scale-annotation')
        // create a div at the same level as domElement, each use to display the respective x,y,z value of the selector
        this.domElement.parentNode.appendChild(this.selectorPositionX)
        this.domElement.parentNode.appendChild(this.selectorPositionY)
        this.domElement.parentNode.appendChild(this.selectorPositionZ)
        this.domElement.parentNode.appendChild(this.selectorRotationAngle)
        this.domElement.parentNode.appendChild(this.selectorScale)

        this.selectorPositionX.style.opacity = 0
        this.selectorPositionY.style.opacity = 0
        this.selectorPositionZ.style.opacity = 0
        this.selectorRotationAngle.style.opacity = 0
        this.selectorScale.style.opacity = 0
    }

    refreshAnnotations() {
        let annotationLabel = null
        let annotationLabelPos = null

        switch (this.mode) {
            case 'translate':
                    // If the axis string contains X, display the annotatio,
                if (this.activeAxes.includes('X')) {
                    annotationLabel = this.selectorPositionX
                    annotationLabelPos = new THREE.Vector3(this.targetTransform.position.x / 3, 0, 0)

                    this.setAnnotationText(annotationLabel, UnitConverter.convert(this.UnityConvertedTransform.x, this.unitSystem))
                    this.setAnnotationPosition(annotationLabel, annotationLabelPos, this.domElement)
                }
                if (this.activeAxes.includes('Y')) {
                    annotationLabel = this.selectorPositionY
                    annotationLabelPos = new THREE.Vector3(this.targetTransform.position.x, this.targetTransform.position.y / 2, this.targetTransform.position.z)

                    this.setAnnotationText(annotationLabel, UnitConverter.convert(this.UnityConvertedTransform.y, this.unitSystem))
                    this.setAnnotationPosition(annotationLabel, annotationLabelPos, this.domElement)
                }
                if (this.activeAxes.includes('Z')) {
                    annotationLabel = this.selectorPositionZ
                    annotationLabelPos = new THREE.Vector3(this.targetTransform.position.x, 0, this.targetTransform.position.z / 3)

                    // Note: adjust z value display based on left or right hand coordinate system
                    this.setAnnotationText(annotationLabel, UnitConverter.convert(this.leftHandCoordinateSystem ? this.UnityConvertedTransform.z : -this.UnityConvertedTransform.z, this.unitSystem))
                    this.setAnnotationPosition(annotationLabel, annotationLabelPos, this.domElement)
                }
                break;
            case 'rotate':
                annotationLabel = this.selectorRotationAngle
                annotationLabelPos = new THREE.Vector3(this.targetTransform.position.x, this.targetTransform.position.y, this.targetTransform.position.z)
                switch (this.activeAxes) {
                    case 'X':
                        // Adjust x angle display based on left or right hand coordinate system
                        var adjustedAngleX = this.leftHandCoordinateSystem ? -this.targetTransform.rotation.x : this.targetTransform.rotation.x
                        this.setAnnotationText(annotationLabel, (adjustedAngleX * (180 / Math.PI)).toFixed(0) + " °")
                        break;
                    case 'Y':
                        // Adjust y angle display based on left or right hand coordinate system
                        var adjustedAngleY = this.leftHandCoordinateSystem ? -this.targetTransform.rotation.y : this.targetTransform.rotation.y
                        this.setAnnotationText(annotationLabel, (adjustedAngleY * (180 / Math.PI)).toFixed(0) + " °")
                        break;
                    case 'Z':
                        // No adjustment needed for z angle for left or right hand coordinate system
                        this.setAnnotationText(annotationLabel, (this.targetTransform.rotation.z * (180 / Math.PI)).toFixed(0) + " °")
                        break;
                }
                this.setAnnotationPosition(annotationLabel, annotationLabelPos, this.domElement)
                break;
            case 'scale':
                annotationLabel = this.selectorScale
                annotationLabelPos = new THREE.Vector3(this.targetTransform.position.x, this.targetTransform.position.y, this.targetTransform.position.z)

                this.setAnnotationText(annotationLabel, 'x ' + this.targetTransform.scale.y.toFixed(1))
                this.setAnnotationPosition(annotationLabel, annotationLabelPos, this.domElement)
                break;
        }
    }

    setAnnotationText(div, text) {
        div.style.opacity = 0.85
        div.textContent = text
    }

    // Computes the 2d in-canvas position for the div annotation based on the 3d vector position
    setAnnotationPosition(div, vector, canvas) {
        vector.project(this.camera)
        vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio))
        vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio))

        // Boundary checks
        const minX = 0
        const minY = 0
        const maxX = (canvas.width - div.offsetWidth) / window.devicePixelRatio
        const maxY = (canvas.height - div.offsetHeight) / window.devicePixelRatio

        vector.x = Math.max(minX, Math.min(vector.x, maxX))
        vector.y = Math.max(minY, Math.min(vector.y, maxY))

        div.style.top = `${vector.y}px`
        div.style.left = `${vector.x}px`
    }

    resetDiv(div) {
        div.style.opacity = 0
        div.style.top = '0px'
        div.style.left = '0px'
    }
    
    createSegmentEnding() {
        // create empty div, of class "marker", each use to place a marker at the beginning and end of the lines affected by user interaction
        this.segmentEnding1 = document.createElement('div')
        this.segmentEnding2 = document.createElement('div')
        this.segmentEnding3 = document.createElement('div')
        this.segmentEnding4 = document.createElement('div')
        // Add class to the div
        this.segmentEnding1.classList.add('segment-ending')
        this.segmentEnding2.classList.add('segment-ending')
        this.segmentEnding3.classList.add('segment-ending')
        this.segmentEnding4.classList.add('segment-ending')
        // create a div at the same level as domElement, each use to display the respective x,y,z value of the selector
        this.domElement.parentNode.appendChild(this.segmentEnding1)
        this.domElement.parentNode.appendChild(this.segmentEnding2)
        this.domElement.parentNode.appendChild(this.segmentEnding3)
        this.domElement.parentNode.appendChild(this.segmentEnding4)

        this.segmentEnding1.style.opacity = 0
        this.segmentEnding2.style.opacity = 0
        this.segmentEnding3.style.opacity = 0
        this.segmentEnding4.style.opacity = 0
    }

    refreshSegmentEndings() {
        var segEnding = null
        var segPos = null
        switch (this.mode) {
            case 'translate':
                // If the axis string contains X, display the annotation
                if (this.activeAxes.includes('X')) {
                    segEnding = this.segmentEnding1
                    segPos = new THREE.Vector3(...this.linePoints[0])
                    this.setEndingPosition(segEnding, segPos, this.domElement)
                    segEnding = this.segmentEnding2
                    segPos = new THREE.Vector3(...this.linePoints[1])
                    this.setEndingPosition(segEnding, segPos, this.domElement)

                    this.segmentEnding1.style.opacity = 1
                    this.segmentEnding2.style.opacity = 1
                }
                if (this.activeAxes.includes('Z')) {
                    segEnding = this.segmentEnding2
                    segPos = new THREE.Vector3(...this.linePoints[1])
                    this.setEndingPosition(segEnding, segPos, this.domElement)
                    segEnding = this.segmentEnding3
                    segPos = new THREE.Vector3(...this.linePoints[2])
                    this.setEndingPosition(segEnding, segPos, this.domElement)

                    this.segmentEnding2.style.opacity = 1
                    this.segmentEnding3.style.opacity = 1
                }
                if (this.activeAxes.includes('Y')) {
                    segEnding = this.segmentEnding3
                    segPos = new THREE.Vector3(...this.linePoints[2])
                    this.setEndingPosition(segEnding, segPos, this.domElement)
                    segEnding = this.segmentEnding4
                    segPos = new THREE.Vector3(...this.linePoints[3])
                    this.setEndingPosition(segEnding, segPos, this.domElement)

                    this.segmentEnding3.style.opacity = 1
                    this.segmentEnding4.style.opacity = 1
                }
                break;
            case 'rotate':
            case 'scale':
                break;
        }
    }

    // Computes the 2d in-canvas position for the div endings based on the 3d vector position
    setEndingPosition(div, vector, canvas) {
        vector.project(this.camera)
        vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio))
        vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio))

        // Boundary checks
        const minX = 0
        const minY = 0
        const maxX = (canvas.width - div.offsetWidth) / window.devicePixelRatio
        const maxY = (canvas.height - div.offsetHeight) / window.devicePixelRatio

        vector.x = Math.max(minX, Math.min(vector.x, maxX))
        vector.y = Math.max(minY, Math.min(vector.y, maxY))

        div.style.top = `${vector.y}px`
        div.style.left = `${vector.x}px`
    }

    // Utility functions for line styling based on distance from camera
    getLineWidth() {
        return this.distance / 170
    }
    getLineDashed() {
        return true;
    }
    getLineDashScale() {
        return 1
    }
    getLineDashSize() {
        return this.distance / 40
    }
    getLineGapSize() {
        return this.distance / 60
    }

    dispose() {
        if (this.matLine)
            this.matLine.dispose()
        if (this.line) {
            this.line.geometry.dispose()
            this.line.material.dispose()
            this.scene.remove(this.line)
            this.line.dispose()
        }
        // Destroy annotations
        this.selectorPositionX.remove()
        this.selectorPositionY.remove()
        this.selectorPositionZ.remove()
        this.selectorRotationAngle.remove()
        this.selectorScale.remove()

        // Destroy segment endings
        this.segmentEnding1.remove()
        this.segmentEnding2.remove()
        this.segmentEnding3.remove()
        this.segmentEnding4.remove()
    }
}
