// Description: Command stack for undo/redo functionality
// Usage:
// import { CommandStack } from '@/assets/js/utils/command-stack'
// import { TranslateNodeCommand } from '@/assets/js/utils/commands'
//
// const commandStack = new CommandStack()
// commandStack.do(new TranslateNodeCommand(node, { x: 1, y: 2, z: 3 }))
// commandStack.undo()
// commandStack.redo()
//

class CommandStack {
  constructor() {
    this.undoStack = []
    this.redoStack = []
  }

  // Execute and record a command.
  // Returns the type of command executed.
  do(command) {
    command.do()
    this.undoStack.push(command)
    this.redoStack = [] // Clear redo stack on new command
  }

  // Undo the last command
  // Returns the command undone.
  undo() {
    const command = this.undoStack.pop()
    if (!command) return null
    this.redoStack.push(command)
    command.undo()
    return command
  }

  // Redo the last undone command
  // Returns the last command redone.
  redo() {
    const command = this.redoStack.pop()
    if (!command) return null
    this.undoStack.push(command)
    command.do()
    return command
  }

  // Returns true if there are commands to undo
  canUndo() {
    return this.undoStack.length > 0
  }

  //Returns true if there are commands to redo
  canRedo() {
    return this.redoStack.length > 0
  }

  // Reset the was_saved flag on all commands
  onSave() {
    this.undoStack.forEach(command => {
      command.was_saved = false
    })
  }

  // Clear the undo and redo stacks
  clear() {
    this.undoStack = []
    this.redoStack = []
  }
}

export { CommandStack }
